<template>
  <header ref="mainHeader">
    <MainNavigation />
  </header>
</template>

<script setup>
import MainNavigation from "@/components/navigation/MainNavigation";

import Setup from "./HeaderSetup";
const { mainHeader } = Setup();
</script>