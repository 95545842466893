<template>
  <div>
    <MDBDropdown
      v-model="dropdown"
      btn-group
      class="h-100"
    >
      <MDBBtn
        color="primary"
        size="sm"
        :class="['xs'].includes($store.state.app.windowSize) ? 'ps-2 pe-0' : 'ps-3 pe-1'"
        :title="['xs'].includes($store.state.app.windowSize) ? $tcust('ThemeSwitcher.button.desc') : ''"
        @click="darkLightChange"
      >
        <span v-if="!['xs'].includes($store.state.app.windowSize)">{{ $tcust("ThemeSwitcher.button.text") }}</span>
        <div v-else>
          <MDBIcon
            icon="sun"
            icon-style="fas"
            size="lg"
            class="me-1"
          />
          <MDBIcon
            icon="slash"
            icon-style="fas"
            class="ms-n2"
            style="transform: rotate(65deg)"
          />
          <MDBIcon
            icon="moon"
            icon-style="fas"
            size="lg"
            class="me-2 ms-n1"
          />
        </div>
      </MDBBtn>
      <MDBDropdownToggle
        split
        size="sm"
        class="h-100 pe-2"
        :title="$tcust('common.dropdown.toogle.desc')"
        @click="dropdown = !dropdown"
      />
      <MDBDropdownMenu :class="$store.state.app.theme === 'light' ? 'border border-primary' : ''">
        <div class="m-2 ps-2">
          <MDBRadio
            v-model="appTheme"
            :label="$tcust('ThemeSwitcher.dropdown.system')"
            name="radioThemeSystem"
            value="system"
          />
        </div>
        <hr class="p-0 my-0 mx-3">
        <div class="m-2 ps-2">
          <MDBRadio
            v-model="appTheme"
            :label="$tcust('ThemeSwitcher.dropdown.light')"
            name="radioThemeLight"
            value="light"
          />
        </div>
        <hr class="p-0 my-0 mx-3">
        <div class="m-2 ps-2">
          <MDBRadio
            v-model="appTheme"
            :label="$tcust('ThemeSwitcher.dropdown.dark')"
            name="radioThemeDark"
            value="dark"
          />
        </div>
      </MDBDropdownMenu>
    </MDBDropdown>
  </div>
</template>

<script setup>
import {
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBBtn,
  MDBIcon,
  MDBRadio,
} from "mdb-vue-ui-kit";
import { ref } from "vue";

const dropdown = ref(false);

import Setup from "./ThemeSwitcherSetup";

const { appTheme, darkLightChange } = Setup();
</script>

<style lang="scss" scoped>
.fa-moon {
  transform: scale(0.95);
}
</style>
