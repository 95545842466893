<template>
  <div>
    <MDBDropdown
      v-model="dropdown"
      btn-group
      class="h-100"
    >
      <MDBDropdownToggle
        size="sm"
        color="primary"
        :class="['xs'].includes($store.state.app.windowSize) ? 'd-flex align-items-center py-1 px-2' : 'pe-2'"
        :title="['xs'].includes($store.state.app.windowSize) ? $tcust('LangSwitcher.button.desc') : ''"
        @click="dropdown = !dropdown"
      >
        <span v-if="!['xs'].includes($store.state.app.windowSize)">{{ $tcust("LangSwitcher.button.text") }}&nbsp;</span>
        <MDBIcon
          v-else
          icon="language"
          icon-style="fas"
          size="2x"
          class="me-2"
        />
      </MDBDropdownToggle>
      <MDBDropdownMenu :class="$store.state.app.theme === 'light' ? 'border border-primary' : ''">
        <div
          v-for="(language, index) of supportedLang"
          :key="index"
        >
          <div class="m-2 ps-2">
            <MDBRadio
              v-model="lang"
              :label="$tcust('LangSwitcher.dropdown.' + language)"
              :name="'radioLang' + language"
              :value="language"
            />
          </div>
          <hr
            v-if="index !== supportedLang.length - 1"
            class="p-0 my-0 mx-3"
          >
        </div>
      </MDBDropdownMenu>
    </MDBDropdown>
  </div>
</template>

<script setup>
import {
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBIcon,
  MDBRadio,
} from "mdb-vue-ui-kit";
import { ref } from "vue";

const dropdown = ref(false);

import Setup from "./LangSwitcherSetup";

const { lang, supportedLang } = Setup();
</script>