import { computed, onMounted, ref, watchEffect } from "vue";
import { useStore } from "vuex";

export default function Setup() {
  const store = useStore();
  const theme = computed(() => {
    return store.state.app.theme;
  });

  const mainHeader = ref(null)

  const reactTheme = () => {
    let headerClass = mainHeader.value.classList
    switch (theme.value) {
      case 'dark':
        headerClass.add('bg-dark')
        break;
      default:
        break;
    }
  };

  onMounted(() => {
    reactTheme();
    watchEffect(() => theme, reactTheme());
  });

  return {
    mainHeader
  }
}
