<template>
  <div>
    <MDBDropdown
      v-model="dropdown"
      btn-group
      class="shadow-0 h-100"
    >
      <MDBDropdownToggle
        size="md"
        color="transparent"
        class="btn-icon p-0 text-primary rounded-circle"
        :title="$tcust('ProfilDropdown.toogleTitle')"
        @click="dropdown = !dropdown"
      >
        <Image
          v-if="loaded"
          :image="PP"
          :size="'tiny'"
          :isPP="true"
          class="text-primary"
        />
        <MDBIcon
          v-else
          icon="user-circle"
          icon-style="fas"
          size="2x"
          class="text-primary m-auto"
        />
      </MDBDropdownToggle>

      <MDBDropdownMenu
        class="px-3 py-1 sidenav-primary"
        :class="$store.state.app.theme === 'light' ? 'border border-primary' : ''"
      >
        <div class="sidenav-item h-fit">
          <a
            class="d-flex flex-row align-items-center fs-6 text-start w-auto h-fit py-1 px-2 my-2 sidenav-link ripple-surface"
            @click="$router.push({ name: 'Profil' }); dropdown = !dropdown"
          >
            <MDBIcon
              icon="user"
              icon-style="far"
              size="lg"
              class="me-2"
            />
            {{ $tcust('router.Profil') }}
          </a>
        </div>

        <hr class="w-75 text-primary mx-auto my-0" />

        <div class="sidenav-item h-fit">
          <a
            class="d-flex flex-row align-items-center fs-6 text-start w-auto h-fit py-1 px-2 my-2 sidenav-link ripple-surface"
            @click="$router.push({ name: 'LicensesList' }); dropdown = !dropdown"
          >
            <MDBIcon
              icon="business-time"
              icon-style="fas"
              size="lg"
              class="me-2"
            />
            {{ $tcust('router.LicensesList') }}
          </a>
        </div>

        <hr class="w-75 text-primary mx-auto my-0" />

        <div class="sidenav-item h-fit">
          <a
            class="d-flex flex-row align-items-center justify-content-center fs-6 text-end w-auto  h-fit py-1 px-2 my-2 sidenav-link ripple-surface"
            @click="$router.push({ name: 'Accueil' })"
          >
            {{ $tcust('router.Accueil') }}
            <MDBIcon
              icon="sign-out-alt"
              icon-style="fas"
              size="lg"
              class="ms-1"
            />
          </a>
        </div>
      </MDBDropdownMenu>
    </MDBDropdown>
  </div>
</template>

<script setup>
import {
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBIcon,
} from "mdb-vue-ui-kit";
import { onMounted, ref } from "vue";

const dropdown = ref(false);

// Until tiny image comes automatically
import { useStore } from "vuex";
const store = useStore();
const loaded = ref(false);
const PP = ref(null);
onMounted(async () => {
  await store.dispatch("getUserPhoto", store.state.user.UserName.toString())
  .then((res) => PP.value = res.OriginalImage)
  .catch(() => PP.value = null)
  .finally(() => loaded.value = true)
})
</script>

<style lang="scss" scoped>
@import "styles/var.scss";
.light-theme .dropdown-menu {
  background-color: $gray-100 !important;
}
.dropdown-toggle {
  min-width: 36px;
  max-width: 36px;
  min-height: 36px;
  max-height: 36px;
  font-size: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  &::after {
    display: none;
  }
}
a {
  transform: scale(1.1);
  i {
    min-width: 30px;
  }
}
</style>
