<template>
  <div class="d-flex flex-column">
    <Header class="above" />

    <div class="flex-grow-1 overflow-auto">
      <div
        v-if="$route.name == 'Accueil'"
        class="d-flex flex-column w-fit mx-auto"
      >
        <div class="text-center my-4 mt-5">
          <div class="fw-bold fs-5 text-primary">
            {{ $tcust("Accueil.welcome.welcome") }}
          </div>
          <div class="opacity-85">
            {{ $tcust("Accueil.welcome.text") }}
          </div>
        </div>

        <MDBContainer id="Connexion">
          <div
            class="gradient custom-box shadow-5 w-100 m-auto"
          >
            <form
              v-if="!loading"
              class="p-3 bg-box"
              @submit.prevent="submit"
            >
              <!-- Email input -->
              <MDBInput
                id="UserName"
                v-model="UserName"
                type="email"
                autocomplete="username"
                required
                :label="$tcust('common.form.email')"
                wrapper-class="mb-4"
                class="text-center mb-3"
              />
              <!-- Password input -->
              <MDBInput
                id="Password"
                v-model="Password"
                type="password"
                autocomplete="current-password"
                required
                :label="$tcust('common.form.password')"
                wrapper-class="mb-4"
                class="text-center"
              />

              <div v-if="$store.state.app.error">
                <hr class="w-75 m-auto mb-2 mt-3" />
                <div class="bg-danger rounded-5 overflow-hidden">
                  <div class="bg-box fst-italic text-center">
                    <Error />
                  </div>
                </div>
              </div>

              <!-- Forgot ? -->
              <MDBRow>
                <MDBCol>
                  <div
                    class="w-fit mx-auto my-3"
                    :class="$store.state.app.error ? 'fw-bold' : ''"
                  >
                    <router-link :to="{ name: 'ForgotPassword' }">
                      {{ $tcust("Accueil.forgotPassword") }}
                    </router-link>
                  </div>
                  <hr v-if="$store.state.app.error" class="w-75 m-auto mb-3 mt-n1" />
                </MDBCol>
              </MDBRow>

              <!-- Submit button -->
              <MDBBtn
                color="primary"
                block
                type="submit"
              >
                <span>{{ $tcust("Accueil.submit") }}</span>
              </MDBBtn>
            </form>

            <Loader
              v-else
              class="w-100 h-100 py-5 bg-box"
            />
          </div>

          <div class="mt-3 w-100">
            {{ $tcust("Accueil.subscribe.text") }}
            <router-link
              :to="{ name: 'SignIn' }"
              class="ms-1"
              :title="$tcust('router.SignIn')"
            >
              {{ $tcust("Accueil.subscribe.link.text") }}
            </router-link>
          </div>

          <div class="mt-2 w-100">
            {{ $tcust("Accueil.knowUs.text") }}
            <a
              href="https://eecheck.app"
              class="ms-1"
              target="_blank"
              :title="$tcust('Accueil.knowUs.link.desc')"
            >
              {{ $tcust("Accueil.knowUs.link.text") }}
            </a>
          </div>
        </MDBContainer>
      </div>

      <router-view
        v-else
        class="main-view w-100"
        :class="$route.meta.requireAuth ? 'h-100' : 'h-fit'"
      />
      <Brand
        v-if="!$route.meta.requireAuth"
        :size="'4x'"
        :background="true"
        class="opacity-20 w-100 p-5 px-3"
      />
    </div>
  </div>
</template>

<script setup>
import { MDBRow, MDBCol, MDBContainer, MDBInput, MDBBtn } from "mdb-vue-ui-kit";
import Header from "@/components/layout/Header";

import { ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { mutateKey } from '@/helpers';

const router = useRouter();
const store = useStore();

const loading = ref(false);

const UserName = ref("");
const Password = ref("");

const submit = async () => {
  loading.value = true;
  await store
    .dispatch("login", { UserName: UserName.value, Password: Password.value })
    .then(() => {
      return store.dispatch("getUser")
    })
    .then(() => {
      return router.push({
        name: "Home",
      });
    })
    .then(() => {
      mutateKey('licenseChosen', store.state.user.OneLicensePlan);
      mutateKey('licenseChosenKey', store.state.user.OneLicenseKey);
      loading.value = false;
      return
    })
    .catch(() => {
      loading.value = false;
      return false
    })
};
</script>

<style lang="scss">
#Connexion {
  min-width: 280px;
  width: 350px;
  max-width: 95vw;
}
</style>